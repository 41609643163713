import * as React from 'react'
import { getLanguage } from '../utils/i18n'
import { cn } from '../utils'

const SHOW_FRENCH = getLanguage().toLowerCase().startsWith('fr')

export const GoogleAppStoreBadge = ({ className = '' }) =>
  SHOW_FRENCH ? <GoogleAppStoreBadgeFr className={className} /> : <GoogleAppStoreBadgeEn className={className} />

const GoogleAppStoreBadgeEn = ({ className = '' }) => (
  <img
    alt="Get it on Google Play"
    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
    className={cn('w-[250px]', className)}
  />
)
const GoogleAppStoreBadgeFr = ({ className = '' }) => (
  <img
    alt="L'obtenir sur Google Play"
    src="https://play.google.com/intl/en_us/badges/static/images/badges/fr-ca_badge_web_generic.png"
    className={cn('w-[250px] px-[14px]', className)}
  />
)
