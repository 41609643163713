import { SharedUtils } from '@walter/shared'
import isInteger from 'lodash/isInteger'
import { z } from 'zod'
import { i18n, t } from '../../utils'
import { fieldIsTooLong } from './common'
import { emptyStringToNull } from './transformationUtils'

export const ResidentProfileManagerWebi18nFields = {
  firstName: t('first-name'),
  lastName: t('last-name'),
  title: t('title'),
  email: t('email'),
  secondaryEmail: t('secondary-email'),
  phone: t('mobile-phone-number'),
  homePhone: t('home-phone-number'),
  officePhone: t('office-phone-number'),
  extension: t('extension'),
  preferedLanguage: t('preferred-language'),
  emergencyContact: t('emergency-contact'),
  note: t('note'),

  property: t('living-unit'),
  properties: t('owning-units'),
  projectsTenants: t('is-tenant'),

  accessKeys: t('access-keys'),
  remotes: t('remotes'),

  parking: t('parkings'),

  lockers: t('lockers'),

  hasAppNotificationEnabled: t('has-app-notification-enabled'),
  hasEmailNotificationEnabled: t('has-email-notification-enabled'),

  totalLeaseAmount: t('total-lease-amount'),
  leaseEnd: t('lease-end'),
  leaseStart: t('lease-start'),
  rentLeaveDate: t('rent-leave-date'),
  accountsReceivableAmount: t('receivable-amount'),
  accountsReceivableComments: t('accounts-receivable-comments'),
}

export const synchronizedResidentProfileManagerWebSchema = z.object({
  secondaryEmail: z
    .string()
    .email({ message: t('invalid-email') })
    .or(z.literal('').nullable().optional()),
  emergencyContact: z.string().optional().nullable(),
  note: z.string().optional().nullable(),
  hasAppNotificationEnable: z.boolean().optional(),
  hasEmailNotificationEnable: z.boolean().optional(),
  accessKeys: z
    .array(z.object({ id: z.string() }))
    .nullable()
    .optional(),
  remotes: z
    .array(z.object({ id: z.string() }))
    .nullable()
    .optional(),
})

export const residentProfileManagerWebSchema = synchronizedResidentProfileManagerWebSchema
  .merge(
    z.object({
      firstName: z
        .string()
        .min(1, { message: i18n.t('input-is-required', { field: ResidentProfileManagerWebi18nFields.firstName }) })
        .max(30, fieldIsTooLong(30, ResidentProfileManagerWebi18nFields.title)),
      lastName: z
        .string()
        .min(1, { message: i18n.t('input-is-required', { field: ResidentProfileManagerWebi18nFields.lastName }) })
        .max(30, fieldIsTooLong(30, ResidentProfileManagerWebi18nFields.lastName)),
      title: z.string().optional().nullable(),
      email: z
        .string()
        .email({ message: t('invalid-email') })
        .or(z.literal('').nullable().optional()),
      phone: z
        .object({
          number: z
            .string()
            .max(11, { message: i18n.t('input-must-be-phone-number') })
            .regex(SharedUtils.phoneNumberRegex, {
              message: i18n.t('input-must-be-phone-number'),
            })
            .or(z.literal('').nullable().optional()),
        })
        .optional()
        .nullable(),
      homePhone: z
        .object({
          number: z
            .string()
            .max(11, { message: i18n.t('input-must-be-phone-number') })
            .regex(SharedUtils.phoneNumberRegex, {
              message: i18n.t('input-must-be-phone-number'),
            })
            .or(z.literal('').nullable().optional()),
        })
        .optional()
        .nullable(),
      officePhone: z.object({
        number: z
          .string()
          .max(11, { message: i18n.t('input-must-be-phone-number') })
          .regex(SharedUtils.phoneNumberRegex, {
            message: i18n.t('input-must-be-phone-number'),
          })
          .or(z.literal('').nullable().optional()),
        extension: z
          .string()
          .nullable()
          .optional()
          .refine(
            (value) => {
              return (value?.trim().length ?? 0) > 0 && typeof value === 'string'
                ? isInteger(parseInt(value?.trim())) && parseInt(value?.trim()) > 0
                : true
            },
            {
              message: t('invalid-extension-number'),
            },
          ),
      }),
      preferedLanguage: z.string().optional(),
      property: z
        .object({
          id: z.string().nullable().optional(),
          address: z.object({ apartmentNumber: z.string().nullable().optional() }).nullable().optional(),
        })
        .nullable()
        .optional(),
      // We display with propertyForProjectId but edit with property
      propertyForProjectId: z
        .object({
          id: z.string().nullable().optional(),
          address: z.object({ apartmentNumber: z.string().nullable().optional() }).nullable().optional(),
        })
        .nullable()
        .optional(),
      properties: z
        .array(z.object({ id: z.string().nullable() }))
        .nullable()
        .optional(),
      // We display with ownedProperties but edit with properties
      ownedProperties: z
        .array(z.object({ id: z.string().nullable(), address: z.object({ apartmentNumber: z.string().nullable() }) }))
        .nullable()
        .optional(),
      projectsTenants: z
        .array(z.object({ id: z.string() }))
        .nullable()
        .optional(),
      parkings: z
        .array(z.object({ id: z.string() }))
        .nullable()
        .optional(),
      lockers: z
        .array(z.object({ id: z.string() }))
        .nullable()
        .optional(),
      totalLeaseAmount: z
        .string()
        .transform(emptyStringToNull)
        .refine(
          (val) => {
            return val == null || (!isNaN(val) && val >= 0)
          },
          {
            message: i18n.t('input-must-be-positive-number', {
              field: ResidentProfileManagerWebi18nFields.totalLeaseAmount,
            }),
          },
        )
        .or(z.number())
        .nullable()
        .optional(),
      leaseEnd: z.string().or(z.date().optional().nullable()),
      leaseStart: z.string().or(z.date().optional().nullable()),
      rentLeaveDate: z.string().or(z.date().optional().nullable()),
      accountsReceivableAmount: z
        .string()
        .transform(emptyStringToNull)
        .refine(
          (val) => {
            return val == null || !isNaN(val)
          },
          {
            message: i18n.t('input-must-be-number', {
              field: ResidentProfileManagerWebi18nFields.accountsReceivableAmount,
            }),
          },
        )
        .or(z.number())
        .nullable()
        .optional(),
      accountsReceivableComments: z.string().optional().nullable(),
    }),
  )
  .refine(
    (value) => {
      if (value.projectsTenants?.length && value.projectsTenants?.length > 0) {
        return !value.properties || value.properties.every((property) => property.id !== value.property?.id)
      }
      return true
    },
    { message: t('tenant-cant-be-tenant-and-owner-description'), path: ['properties'] },
  )

export type ResidentProfileManagerWeb = z.infer<typeof residentProfileManagerWebSchema>

export type ResidentProfileManagerWebFormContext = z.infer<typeof residentProfileManagerWebSchema>
