import { useApolloClient } from '@apollo/client'
import { Config } from '@walter/shared'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { cn, t } from '../utils'
import { Icon } from './Icon'

const HEALTH_CHECK_ENDPOINT = `${Config.restEndPoint}/healthz`

export function TemporarilyUnavailable() {
  const [isRetrying, setIsRetrying] = React.useState(true)
  const [numOfAttempts, setNumOfAttempts] = React.useState(0)
  const client = useApolloClient()
  const navigate = useNavigate()

  React.useEffect(() => {
    const controller = new AbortController()
    const checkHealth = async () => {
      try {
        await fetch(HEALTH_CHECK_ENDPOINT, { signal: controller.signal })
        await client.resetStore()
        navigate('/')
      } catch {
        setIsRetrying(false)
      }
    }
    checkHealth()

    return () => {
      controller.abort()
    }
  }, [client])

  async function onRetry() {
    setIsRetrying(true)
    setNumOfAttempts((prev) => prev + 1)
    try {
      await fetch(HEALTH_CHECK_ENDPOINT)
      await client.resetStore()
      navigate('/')
    } catch (_e) {
      setTimeout(() => {
        setIsRetrying(false)
      }, 700)
    }
  }
  return (
    <div className="flex flex-col gap-4 h-dvh flex-1 justify-center items-center bg-background">
      <Image />
      <h1 className="text-2xl font-bold text-foreground">{t('temporarily-unavailable')}</h1>
      <h1 className="text-foreground">{t('sorry-were-updating-our-site')}</h1>
      <div />
      <button
        disabled={isRetrying}
        onClick={onRetry}
        className="relative text-primary-foreground bg-primary text-sm px-3 h-9 rounded font-medium flex items-center justify-center [&:not(:disabled)]:active:scale-[0.99]  transition-transform duration-75 ease-in-out focus:outline-none focus:ring-2 focus:ring-input focus:ring-offset-2 active:ring-0 active:ring-offset-0 hover:bg-opacity-95"
      >
        <span className={cn(isRetrying && 'opacity-0')}>{t('try-again')}</span>
        {isRetrying && (
          <div className="absolute inset-0 justify-center items-center flex animate-spin">
            <Icon icon="loading" size="small" />
          </div>
        )}
      </button>
      {numOfAttempts > 0 ? (
        <p className="text-xs text-foreground/90 animate-in fade-in zoom-in">
          {t('attempt')} #
          <span key={`attempt-${numOfAttempts}`} className="inline-block animate-in zoom-in">
            {numOfAttempts}
          </span>
        </p>
      ) : (
        <>
          {/* For screen readers and to prevent layout shift */}
          <p className="text-xs opacity-0 select-none" aria-hidden>
            {t('no-connection-retry-attempts-yet')}
          </p>
        </>
      )}
    </div>
  )
}

function Image() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      viewBox="0 0 384 290.3"
      className="h-80"
    >
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html: `
        .st0 {
          fill: none;
        }
  
        .st1 {
          fill: #e0e3e7;
        }
  
        .st2 {
          fill: #1f1f6c;
        }
  
        .st3 {
          fill: #f9c4a0;
        }
  
        .st4 {
          clip-path: url(#clippath-1);
        }
  
        .st5 {
          clip-path: url(#clippath-3);
        }
  
        .st6 {
          clip-path: url(#clippath-2);
        }
  
        .st7, .st8, .st9 {
          fill: #fff;
        }
  
        .st8 {
          stroke-width: 1px;
        }
  
        .st8, .st9 {
          stroke: #1f1f6c;
          stroke-miterlimit: 10;
        }
  
        .st9 {
          stroke-width: .4px;
        }
  
        .st10 {
          fill: #004eff;
        }
  
        .st11 {
          clip-path: url(#clippath);
        }`,
          }}
        />
        <clipPath id="clippath">
          <rect className="st0" x="245.3" y="119" width="135.7" height="58.7" />
        </clipPath>
        <clipPath id="clippath-1">
          <rect className="st0" x="3" y="79" width="35.7" height="37.3" />
        </clipPath>
        <clipPath id="clippath-2">
          <rect className="st0" x="9.3" y="280.3" width="357.3" height="4.2" />
        </clipPath>
        <clipPath id="clippath-3">
          <rect className="st0" x="139.5" y="191.8" width="115.2" height="21.2" />
        </clipPath>
      </defs>
      <path
        className="st1"
        d="M366.4,246.8c-9.2,13.8-22.7,23.9-33.6,36.3l-319.5-1.8c15.2-8.8,23.4-46.8,23.6-64.4,0-5.7-.8-11.4-2.1-17-4.4-19.3-13.8-38.4-7.9-57,8.5-26.9,38.6-40.9,39.7-71.1.3-8.8-.7-17.6-.2-26.5,1.3-25.7,21.9-43.8,47.8-42,16.5,1.2,31.9,9.9,44.1,21.1,8.7,7.9,16.5,17.4,27.4,21.8,13.4,5.2,28.6,1.7,42-3.7,13.3-5.3,26.3-12.5,40.6-14.1,7.2-.9,14.1-.5,20.4.9,3.2.7,6.2,1.7,9.1,2.9,32.5,10.7,34.5,42.1,35.8,71.5v.2c.6,13.8.6,28.8,6,41.7,4.9,11.9,15.1,19.8,23.4,29.3,17.4,20,18,50.3,3.5,72Z"
      />
      <path
        className="st7"
        d="M155.3,49.3h-4.1c-.7-3.3-2-6.3-3.7-9.1l3.1-3.1c1.1-1.1,1.1-2.9,0-3.9l-6.8-6.8c-1.1-1.1-2.9-1.1-3.9,0l-2.9,2.9c-2.7-1.7-5.8-3-9-3.8v-4.3c0-1.5-1.3-2.8-2.8-2.8h-9.5c-1.5,0-2.8,1.3-2.8,2.8v4.2c-3.3.7-6.3,2-9.1,3.7l-3.1-3.1c-1.1-1.1-2.9-1.1-3.9,0l-6.8,6.8c-1.1,1.1-1.1,2.9,0,3.9l2.9,2.9c-1.7,2.7-3,5.8-3.8,9h-4.4c-1.5,0-2.8,1.3-2.8,2.8v9.5c0,1.5,1.3,2.8,2.8,2.8h4.2c.7,3.3,2,6.3,3.7,9.1l-3.1,3.1c-1.1,1.1-1.1,2.9,0,3.9l6.8,6.8c1.1,1.1,2.9,1.1,3.9,0l2.9-2.9c2.7,1.8,5.8,3,9,3.8v4.3c0,1.5,1.3,2.8,2.8,2.8h9.5c1.5,0,2.8-1.3,2.8-2.8v-4.1c3.3-.7,6.3-2,9.1-3.7l3.1,3.1c1.1,1.1,2.9,1.1,3.9,0l6.8-6.8c1.1-1.1,1.1-2.9,0-3.9l-2.9-2.9c1.7-2.7,3-5.8,3.9-9h4.3c1.5,0,2.8-1.3,2.8-2.8v-9.5c0-1.6-1.3-2.9-2.9-2.9ZM120.1,74.2c-9.8,0-17.6-7.9-17.6-17.6s8.6-18.3,18.8-17.6c8.8.6,16,7.8,16.5,16.5.6,10.3-7.6,18.8-17.6,18.8Z"
      />
      <path className="st2" d="M163.9,261.3h62.1v17.4h-62.1v-17.4Z" />
      <path
        className="st2"
        d="M345.6,69.9v185.1c0,4.2-3.4,7.7-7.7,7.7H51.8c-4.2,0-7.7-3.4-7.7-7.7V69.9c0-4.2,3.4-7.7,7.6-7.7h286.2c4.3,0,7.7,3.4,7.7,7.7Z"
      />
      <path className="st8" d="M345.2,69.9v175.4H44.5V69.9c0-4.2,3.4-7.7,7.6-7.7h285.4c4.3,0,7.7,3.4,7.7,7.7Z" />
      <path className="st10" d="M51.6,69.9h286.7v167.3H51.6V69.9Z" />
      <path className="st2" d="M245.5,282.5h-101.3v-1.8c0-2.1,1.7-3.7,3.7-3.7h93.8c2.1,0,3.7,1.7,3.7,3.7v1.8Z" />
      <path
        className="st7"
        d="M159.7,196.1h2.7v6.7c0,1.3.6,2.1,1.9,2.1s2-.8,2-2.1v-6.7h2.7v6.7c0,3.1-2.1,4.6-4.8,4.6s-4.6-1.5-4.6-4.6v-6.7h0Z"
      />
      <path
        className="st7"
        d="M177.1,203.2h-1.7v4.1h-2.7v-11.2h4.5c2.7,0,4.1,1.5,4.1,3.6,0,1.8-1.3,3.6-4.2,3.6ZM176.8,201.1c1.1,0,1.5-.6,1.5-1.4s-.5-1.4-1.5-1.4h-1.5v2.8h1.5Z"
      />
      <path
        className="st7"
        d="M194.2,201.6c0,3.3-2.3,5.6-5.9,5.6h-4.2v-11.2h4.2c3.6,0,5.9,2.3,5.9,5.6ZM188.2,204.9c2.1,0,3.3-1.2,3.3-3.3s-1.2-3.3-3.3-3.3h-1.3v6.5h1.3Z"
      />
      <path
        className="st7"
        d="M204.3,205.3h-4.2l-.6,2h-2.9l4.1-11.2h3.2l4.1,11.2h-2.9l-.7-2ZM202.3,199.1l-1.4,4.1h2.8l-1.4-4.1Z"
      />
      <path className="st7" d="M210.1,196.1h8.7v2.1h-2.9v9h-2.7v-9h-3v-2.1Z" />
      <path className="st7" d="M228.6,198.2h-4.1v2.2h3.7v2.1h-3.7v2.5h4.1v2.1h-6.8v-11.2h6.8v2.3Z" />
      <path
        className="st2"
        d="M214.4,136.1h-2.3c-.4-1.8-1.1-3.5-2-5l1.7-1.7c.6-.6.6-1.6,0-2.2l-3.7-3.7c-.6-.6-1.6-.6-2.1,0l-1.7,1.7c-1.5-1-3.2-1.7-5-2.1v-2.4c0-.9-.7-1.5-1.5-1.5h-5.2c-.9,0-1.5.7-1.5,1.5v2.3c-1.8.4-3.5,1.1-5,2l-1.7-1.7c-.6-.6-1.6-.6-2.1,0l-3.7,3.7c-.6.6-.6,1.6,0,2.1l1.7,1.7c-1,1.5-1.7,3.2-2.1,5h-2.4c-.9,0-1.5.7-1.5,1.5v5.2c0,.9.7,1.5,1.5,1.5h2.3c.4,1.8,1.1,3.5,2,5l-1.7,1.7c-.6.6-.6,1.6,0,2.1l3.7,3.7c.6.6,1.6.6,2.1,0l1.7-1.7c1.5,1,3.2,1.7,5,2.1v2.4c0,.9.7,1.5,1.5,1.5h5.2c.9,0,1.5-.7,1.5-1.5v-2.3c1.8-.4,3.5-1.1,5-2l1.7,1.7c.6.6,1.6.6,2.1,0l3.7-3.7c.6-.6.6-1.6,0-2.1l-1.7-1.7c1-1.5,1.7-3.2,2.1-5h2.4c.9,0,1.5-.7,1.5-1.5v-5.3c0-.8-.6-1.5-1.5-1.5ZM194.9,149.8c-5.4,0-9.8-4.4-9.8-9.8s4.8-10.2,10.5-9.8c4.8.3,8.8,4.3,9.1,9.2.3,5.6-4.2,10.3-9.9,10.3Z"
      />
      <path
        className="st7"
        d="M198.8,105.5c0-2.1-1.7-3.9-3.9-3.9h0c-2.1,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9h0c2.1,0,3.9-1.8,3.9-3.9Z"
      />
      <path
        className="st7"
        d="M195,109.4c2.1,0,3.9-1.8,3.9-3.9s-1.8-3.9-3.9-3.9h0c-2.1,0-3.9,1.7-3.9,3.9s1.8,3.9,4,3.9Z"
      />
      <path
        className="st1"
        d="M217.3,113.3c1.2-1.8.6-4.2-1.1-5.4h0c-1.8-1.3-4.2-.7-5.4,1.1-1.2,1.8-.6,4.2,1.2,5.4h0c1.7,1.2,4.2.6,5.3-1.1Z"
      />
      <path
        className="st1"
        d="M223.4,127.8c.9,2,3.2,2.9,5.2,1.9,2-.9,2.9-3.2,1.9-5.2h0c-.9-2.1-3.2-2.9-5.2-2-1.9,1-2.8,3.3-1.9,5.2Z"
      />
      <path
        className="st1"
        d="M225.9,145.3c-.3,2.1,1.2,4.1,3.3,4.4,2.1.3,4.1-1.2,4.4-3.3h0c.3-2.2-1.2-4.2-3.3-4.5-2.1-.3-4.1,1.2-4.4,3.3Z"
      />
      <path
        className="st1"
        d="M218.9,166.8c1.6,1.4,4.1,1.3,5.5-.4h0c1.4-1.7,1.3-4.1-.4-5.6-1.6-1.4-4.1-1.3-5.5.4h0c-1.4,1.7-1.3,4.1.3,5.6Z"
      />
      <path
        className="st1"
        d="M203.6,170.8c-2.1.6-3.3,2.8-2.6,4.8s2.8,3.3,4.8,2.6h0c2.1-.6,3.3-2.8,2.6-4.8-.6-2.1-2.8-3.3-4.9-2.6Z"
      />
      <path
        className="st7"
        d="M186,170.8c-2.1-.6-4.2.6-4.8,2.6s.6,4.2,2.6,4.8h0c2.1.6,4.2-.6,4.8-2.6s-.6-4.2-2.7-4.8Z"
      />
      <path
        className="st7"
        d="M171.2,161.2c-1.4-1.6-3.9-1.8-5.5-.4-1.6,1.4-1.8,3.9-.4,5.5h0c1.4,1.7,3.9,1.9,5.5.5s1.8-3.9.3-5.6Z"
      />
      <path
        className="st7"
        d="M163.9,145.1c-.3-2.1-2.3-3.6-4.4-3.3s-3.6,2.3-3.3,4.4h0c.3,2.2,2.3,3.7,4.5,3.4,2.1-.2,3.6-2.2,3.3-4.5Z"
      />
      <path
        className="st7"
        d="M166.4,127.7c.9-2,0-4.3-1.9-5.2-1.9-.9-4.3,0-5.2,1.9h0c-.9,2.1,0,4.4,1.9,5.3,1.9.9,4.2,0,5.2-2Z"
      />
      <path
        className="st7"
        d="M177.9,114.4c1.8-1.2,2.3-3.6,1.2-5.4-1.2-1.8-3.6-2.3-5.4-1.2h0c-1.8,1.3-2.3,3.7-1.2,5.5,1.3,1.8,3.7,2.3,5.5,1.1Z"
      />
      <path className="st2" d="M69,83.5h251.9v.6H69v-.6Z" />
      <path className="st2" d="M69,222.5h251.9v.6H69v-.6Z" />
      <path
        className="st7"
        d="M376.8,176.5h-127.1c-1.7,0-2.9-1.4-2.9-2.9v-50.9c0-1.7,1.3-2.9,2.9-2.9h127.1c1.7,0,2.9,1.3,2.9,2.9v50.9c0,1.7-1.3,2.9-2.9,2.9Z"
      />
      <g className="st11">
        <path
          className="st2"
          d="M376.8,176.9h-127.1c-1.8,0-3.3-1.5-3.3-3.3v-50.9c0-1.8,1.5-3.3,3.3-3.3h127.1c1.8,0,3.3,1.5,3.3,3.3v50.9c0,1.8-1.5,3.3-3.3,3.3ZM249.7,120.1c-1.4,0-2.5,1.1-2.5,2.5v50.9c0,1.4,1.1,2.5,2.5,2.5h127.1c1.4,0,2.5-1.1,2.5-2.5v-50.9c0-1.4-1.1-2.5-2.5-2.5h-127.1Z"
        />
      </g>
      <path
        className="st10"
        d="M280.7,131.9c-9,0-16.2,7.2-16.2,16.2s7.2,16.2,16.2,16.2,16.2-7.2,16.2-16.2-7.2-16.2-16.2-16.2ZM280.7,161c-7.2,0-12.9-5.8-12.9-12.9s5.8-12.9,12.9-12.9,12.9,5.8,12.9,12.9c0,7.1-5.7,12.9-12.9,12.9Z"
      />
      <path
        className="st2"
        d="M280.7,131.9v3.3c7.2,0,12.9,5.8,12.9,12.9s-5.8,12.9-12.9,12.9-5.8-1-7.9-2.8l-2.4,2.4c2.8,2.3,6.4,3.7,10.3,3.7,9,0,16.2-7.2,16.2-16.2s-7.2-16.2-16.2-16.2Z"
      />
      <path
        className="st10"
        d="M276.7,146.8c0-.3-.2-.6-.7-.6s-.9.5-.9,1.5c.2-.3.6-.6,1.1-.6.9,0,1.4.6,1.4,1.5s-.6,1.6-1.6,1.6-1.8-1-1.8-2.5.5-2.4,1.7-2.4,1.4.6,1.5,1.3h-.8ZM275.2,148.7c0,.5.3.8.8.8s.8-.3.8-.8-.2-.8-.8-.8c-.4,0-.8.3-.8.8Z"
      />
      <path
        className="st10"
        d="M281.6,145.6v.8h-2.1v1.1c.2-.2.6-.4,1-.4,1.1,0,1.5.8,1.5,1.6s-.6,1.7-1.6,1.7-1.6-.6-1.7-1.3h.9c0,.3.3.6.8.6s.8-.4.8-.9-.2-.8-.8-.8-.6.2-.7.5h-.9v-2.8h2.8Z"
      />
      <path
        className="st10"
        d="M283.5,145.7c.6,0,1,.4,1,1s-.5,1-1,1-1-.4-1-1,.4-1,1-1ZM283.1,146.8c0,.3.2.5.3.5.2,0,.4-.2.4-.5s-.2-.5-.4-.5c-.2,0-.3,0-.3.5ZM285.7,145.8h.9l-2.5,4.5h-.9l2.5-4.5ZM286.3,148.3c.6,0,1,.4,1,1s-.4,1-1,1-1-.4-1-1c0-.7.5-1,1-1ZM285.9,149.3c0,.4.2.5.4.5s.4-.2.4-.5-.2-.5-.4-.5-.4,0-.4.5Z"
      />
      <path
        className="st1"
        d="M360.7,142.2h-53.6c-.7,0-1.3-.6-1.3-1.3s.6-1.3,1.3-1.3h53.6c.7,0,1.3.6,1.3,1.3,0,.7-.6,1.3-1.3,1.3Z"
      />
      <path
        className="st1"
        d="M340.5,149.3h-33.3c-.7,0-1.3-.6-1.3-1.3s.6-1.3,1.3-1.3h33.3c.7,0,1.3.6,1.3,1.3,0,.7-.6,1.3-1.3,1.3Z"
      />
      <path
        className="st1"
        d="M318.5,156.5h-11.3c-.7,0-1.3-.6-1.3-1.3s.6-1.3,1.3-1.3h11.3c.7,0,1.3.6,1.3,1.3,0,.7-.6,1.3-1.3,1.3Z"
      />
      <path
        className="st2"
        d="M324.5,155.5c0,.2,0,.3-.2.4-.1.1-.2.2-.4.2s-.3,0-.4-.2c-.1-.1-.2-.2-.2-.4s0-.3.2-.4c.1-.1.2-.2.4-.2s.3,0,.4.2c.1.1.2.2.2.4Z"
      />
      <path
        className="st2"
        d="M328.3,155.5c0,.2,0,.3-.2.4-.1.1-.2.2-.4.2s-.3,0-.4-.2-.2-.2-.2-.4,0-.3.2-.4c.1-.1.2-.2.4-.2s.3,0,.4.2.2.2.2.4Z"
      />
      <path
        className="st2"
        d="M332.2,155.5c0,.2,0,.3-.2.4-.1.1-.2.2-.4.2s-.3,0-.4-.2c-.1-.1-.2-.2-.2-.4s0-.3.2-.4c.1-.1.2-.2.4-.2s.3,0,.4.2c.1.1.2.2.2.4Z"
      />
      <path
        className="st2"
        d="M336.1,155.5c0,.2,0,.3-.2.4-.1.1-.2.2-.4.2s-.3,0-.4-.2c-.1-.1-.2-.2-.2-.4s0-.3.2-.4c.1-.1.2-.2.4-.2s.3,0,.4.2c.1.1.2.2.2.4Z"
      />
      <path
        className="st2"
        d="M339.9,155.5c0,.2,0,.3-.2.4-.1.1-.2.2-.4.2s-.3,0-.4-.2-.2-.2-.2-.4,0-.3.2-.4c.1-.1.2-.2.4-.2s.3,0,.4.2.2.2.2.4Z"
      />
      <path
        className="st2"
        d="M343.8,155.5c0,.2,0,.3-.2.4-.1.1-.2.2-.4.2s-.3,0-.4-.2c-.1-.1-.2-.2-.2-.4s0-.3.2-.4c.1-.1.2-.2.4-.2s.3,0,.4.2c.1.1.2.2.2.4Z"
      />
      <path
        className="st3"
        d="M93.1,176.7c-9-1.6-6.4,7.6-6.3,15.1.6,12-4.5,26.3-.2,28.9,11.5,6.3,17.1-37.9,14.2-40.6-1.5-1.2-4.8-2.5-7.7-3.4Z"
      />
      <path className="st3" d="M90.7,219c-7.4-7.9-15.2,33.4-15.3,45.4,0,12,4,4.7,9.5-13.4,5.6-18.1,8.7-28.9,5.7-32Z" />
      <path className="st3" d="M74.4,262.9l-1.2,4.3,7.2,1.6.6-4.2-6.6-1.7Z" />
      <path
        className="st10"
        d="M80.5,268.8s.8,2.9,4.5,7.2c3.7,4.3,5.1,3.8,4.9,5.1-.2,1.3-19.6-3.9-19.6-3.9l2.9-10.3s7.3,1.6,7.3,1.9Z"
      />
      <path
        className="st2"
        d="M102.7,176.6s1.8,7.8-2.9,29c-.5,2.3-1.1,4.8-1.8,7.6-5.3,21-15,50.3-15.7,52.1h0c0,0-9.2-2.4-9.2-2.4l8.1-43.5-1.5-22.3v-.6c0,0-1.2-15.5-1.2-15.5l24.2-4.4Z"
      />
      <path
        className="st3"
        d="M82.2,182.9c-8.7,2.6-2.1,9.5,1.5,16.2,6.1,10.3,8.4,25.4,13.4,25.7,13.2.3-2.6-41.3-6.5-42.4-1.8-.4-5.4,0-8.4.5Z"
      />
      <path className="st3" d="M99.4,218.6c-9.6-5.1-3.3,36.4.6,47.8,4.7,13.5,5.4,5.4,4.5-15.7-.8-18.9-1.3-30-5.2-32Z" />
      <path className="st3" d="M98.4,265l1.1,7,7.2-.7-1-7-7.4.7Z" />
      <path
        className="st10"
        d="M106.9,271.1s1.7,2.5,6.7,5.3c5,2.9,6,2,6.4,3.3.2,1.3-19.9,2.6-19.9,2.6l-.7-10.6s7.6-.9,7.6-.6Z"
      />
      <path
        className="st2"
        d="M76.2,182.1s-2.7,6.8.9,16.5c4.1,11,13.1,26.9,13.1,26.9l7.3,40.9,9.7-1.4s-2.2-41.9-3.3-48.5c-1.1-6.5-7-34.1-7-34.1l-20.7-.2Z"
      />
      <path
        className="st7"
        d="M91.3,232.8l-1.3-7.2c-.3-.6-5-8.9-9-17.4l.4-.2c4.1,8.7,9,17.3,9,17.4h0c0,0,1.3,7.3,1.3,7.3h-.4Z"
      />
      <path className="st3" d="M94.7,141.8c-4.5,3.9,6.3,32.5,9.9,30.4,6.7-.5-1.4-35.1-9.9-30.4Z" />
      <path className="st3" d="M106.2,157.8l2.1,11,6,2.1-.4,5.6s-9.7,0-12.2-3.3c-2.5-3.3-4.5-13.1-4.5-13.1l9-2.4Z" />
      <path className="st7" d="M106.6,157.8l-2.4.7-3.7,1-3.9,1.1-8.3-22.8c15.4-.6,15.8,11.6,18.2,20Z" />
      <path className="st3" d="M102.3,172.5c0,.9.6,1.7,1.4,2.1,6,3.2,20.6,2.2,20.3,0-.2-1.4-22-8.7-21.6-2.1Z" />
      <path
        className="st3"
        d="M121.3,173.5l-.2-.2.7-.4,1.3-.6c.3-.2.6-.2,1-.2l1.5.4s.2.4-.6.4-.6.2-.8.4l.9.2,2.1.5c.6,0,1.1.7,1,1.3,0,0-.2.9-1.3,1.1l-1.4.4c-.8,0-1.4.3-2.1,0l-2-.8-.2-2.5Z"
      />
      <path
        className="st7"
        d="M103.7,183.4c0,1.7-13.8,1.7-22.2.7-3.5-.4-6-1-6-1.7.9-12.8-.6-22.2-1.6-29.1-1.5-10.8-2-15.3,9.3-16.8l8.6.9c7.6,1.3,10.3,5.3,11.1,11.4,1.1,8-1,19.9.8,34.5Z"
      />
      <path className="st2" d="M95.8,173.1l26.9.3v1.3s-27-.3-27-.3v-1.3Z" />
      <path className="st2" d="M129.8,174.8l-25.1-.2,7.2-17,25.1.2-7.2,17Z" />
      <path className="st9" d="M130.5,174.8l-25-.2,7.2-17,25.1.2-7.3,17Z" />
      <path
        className="st10"
        d="M123.1,164.4c.2,0,.3.2.4.4s.2.4.2.6c0,.2,0,.5,0,.8,0,.3-.1.6-.2.8-.1.3-.3.5-.4.7s-.4.4-.6.5-.4.2-.6.3c-.2,0-.4,0-.6,0s-.3-.2-.4-.4c-.1-.2-.2-.4-.2-.6,0-.2,0-.5,0-.8s.1-.6.2-.8.3-.5.4-.7.4-.4.6-.6c.2-.1.4-.2.6-.3.2,0,.4,0,.6,0Z"
      />
      <path
        className="st3"
        d="M91.9,135.1v4.9c-.5,4.5-7.5-.4-8.3-2.2-.6-1.2.2-1.8.3-4.3v-2.8c-8.4-13.4,5.6-18.8,11-9.3,0,3.1.3,6.1.4,8.5,0,3.7-.4,5.9-3.4,5.2Z"
      />
      <path
        className="st2"
        d="M96.9,118.7c0,1.4-.5,2.8-1.8,3.5-1.3.7-2.7.6-4,0-1.3-.6-2.7-1.3-4.1-.6-2,1-.5,4.1-.5,5.6-.3.2-.8.3-1.1.5-.2-1.1-.6-2.2-1.3-2.1-1,0,0,5.1-.2,5.1s-1.4-1.8-2.1-4c-.2-.5-.3-1-.4-1.4-.7-3.1-.5-7.9,3.3-9,1.6-.4,3.2-.8,4.6-1.5,1.4-.7,2.9-1.4,4.5-.8,1.8.7,2.9,2.7,3,4.7Z"
      />
      <path className="st3" d="M75.7,140.8c-4.7,3.3,2.7,33.5,6.4,31.9,6.5.2,2.2-35.7-6.4-31.9Z" />
      <path className="st3" d="M85.2,159.8l1.1,6.5,10.4-2.3,1.6,5.1s-14.4,7-17.4,4.1c-3-2.9-5-12.2-5-12.2l9.3-1.1Z" />
      <path
        className="st7"
        d="M82.6,143.8l3.1,16.2-10.4,1.7s-2.9-14.9-2.5-18.5c0-.7.4-1.5,1-2.3h0c2.5-3.6,7.8-1.3,8.7,2.9Z"
      />
      <path className="st3" d="M80.8,172.5c.4.7,1.2,1.2,2.1,1.3,6.6.4,29.6-10.7,28.1-11.9-.6-1.3-33.1,5-30.2,10.7Z" />
      <path
        className="st3"
        d="M107,162.1h-.3c0,0,.5-.7.5-.7l.9-1c.2-.2.5-.5.8-.6l1.5-.2s.4.2-.4.6c-.2,0-.4.4-.6.6l.9-.2,1.9-.5c.6,0,1.1,2.3.3,2.9l-.4.3c-.6.4-1.1.8-1.8.9h-2.1c0,0-1.2-2.1-1.2-2.1Z"
      />
      <path
        className="st2"
        d="M91.9,135.1h0c-1.2-.4-2.3-.9-2.9-1.4-1.7-1.4-3.7-6-3.7-6l1.1-.5s1.2,3.3,3.3,3.2c2.1,0,4.2-1.3,5.4-.6.2,3.7-.3,6-3.3,5.2Z"
      />
      <path className="st3" d="M92.1,132.1c0,0,1-.7,2.1-.6.3,0,.8.5,1,.8,0,0-1.8,1.2-3-.2Z" />
      <path
        className="st10"
        d="M298.9,25.3c0-.3,0-.6,0-1,0-5.6-4.5-10.1-10.1-10.1s-8.3,2.9-9.6,7.1h-.7c-1.8,0-3.4.6-4.7,1.7-1.4-.9-3.2-1.3-5-1.3-4.8,0-8.8,3.3-9.9,7.9-.6-.2-1.3-.2-1.9-.2-4.1,0-7.5,3.3-7.5,7.5s1.2,4.7,3,6h23.4c0,0,.2-.2.2-.2,0,0,.2.2.3.2h28.9c2-1.8,3.3-4.5,3.3-7.4,0-5.5-4.4-9.9-9.9-10.1Z"
      />
      <path className="st2" d="M273.6,30.5h11.1v15.8h-11.1v-15.8Z" />
      <path className="st2" d="M279.2,57.5l-9.3-11.6h18.6l-9.3,11.6Z" />
      <g className="st4">
        <path
          className="st10"
          d="M37,94.6h-1.9c-.3-1.5-1-2.9-1.7-4.2l1.4-1.4c.5-.5.5-1.3,0-1.8l-3.1-3.1c-.5-.5-1.4-.5-1.8,0l-1.4,1.3c-1.3-.8-2.7-1.4-4.2-1.8v-2c0-.7-.6-1.3-1.3-1.3h-4.4c-.7,0-1.3.6-1.3,1.3v1.9c-1.5.3-2.9,1-4.2,1.7l-1.4-1.4c-.5-.5-1.3-.5-1.8,0l-3.1,3.1c-.5.5-.5,1.3,0,1.8l1.3,1.3c-.8,1.3-1.4,2.7-1.8,4.2h-2c-.7,0-1.3.6-1.3,1.3v4.5c0,.7.6,1.3,1.3,1.3h2c.3,1.5,1,2.9,1.7,4.2l-1.4,1.4c-.5.5-.5,1.3,0,1.8l3.1,3.1c.5.5,1.4.5,1.8,0l1.4-1.4c1.3.8,2.7,1.4,4.2,1.8v2c0,.7.6,1.3,1.3,1.3h4.4c.7,0,1.3-.6,1.3-1.3v-1.9c1.5-.3,2.9-1,4.2-1.7l1.4,1.4c.5.5,1.3.5,1.8,0l3.1-3.1c.5-.5.5-1.4,0-1.8l-1.4-1.4c.8-1.3,1.4-2.7,1.8-4.2h2c.7,0,1.3-.6,1.3-1.3v-4.4c0-.7-.6-1.3-1.3-1.3ZM20.6,106.2c-4.5,0-8.3-3.7-8.3-8.3s4-8.5,8.8-8.2c4.1.2,7.5,3.6,7.7,7.7.2,4.8-3.5,8.7-8.3,8.7Z"
        />
      </g>
      <path className="st2" d="M85.7,160.1l-2.5-11.2h.2c0,0,2.5,11.1,2.5,11.1h-.2Z" />
      <path className="st7" d="M100.5,200.1l-2.7-11.1h.4c0,0,2.7,11,2.7,11h-.4Z" />
      <g className="st6">
        <path className="st2" d="M10.6,281h355v3.6H10.6v-3.6Z" />
      </g>
      <g className="st5">
        <path className="st10" d="M139.5,191.8h115.2v21.2h-115.2v-21.2Z" />
      </g>
    </svg>
  )
}
