import * as React from 'react'
import { getLanguage } from '../utils/i18n'
import { cn } from '../utils'

const SHOW_FRENCH = getLanguage().toLowerCase().startsWith('fr')

export const AppleAppStoreBadge = ({ className = '' }) =>
  SHOW_FRENCH ? <AppleAppStoreBadgeFr className={className} /> : <AppleAppStoreBadgeEn className={className} />

const AppleAppStoreBadgeEn = ({ className = '' }) => (
  <img
    src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1539648000"
    alt="Download on the App Store"
    className={cn('rounded-[13px] w-[250px] h-[83px] p-2', className)}
  />
)

const AppleAppStoreBadgeFr = ({ className = '' }) => (
  <img
    src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/fr-ca?size=250x83&amp;releaseDate=1539648000"
    alt="Télécharger dans l'App Store"
    className={cn('rounded-[13px] w-[250px] h-[83px] p-2', className)}
  />
)
