import { ApolloProvider } from '@apollo/client'
import { Colors } from '@walter/shared'
import { getLanguage, i18n, MobileBanner, PopoverProvider, ToastProvider } from '@walter/shared-web'
import * as React from 'react'
import { I18nextProvider } from 'react-i18next'
import { SkeletonTheme } from 'react-loading-skeleton'
import { Outlet } from 'react-router-dom'
import { AuthProvider } from '../contexts/Auth'
import HotJarProvider from '../contexts/HotJar'
import { IntercomProvider } from '../contexts/Intercom'
import { MessageProvider } from '../contexts/Message'
import { apolloClient } from '../utils/apollo-client'

const IS_FRENCH = getLanguage().toLowerCase().startsWith('fr')

const MOBILE_URLS = {
  iconImgUrl: 'https://storage.googleapis.com/upload-mtl/logos/manager-app-icon.jpg',
  iosUrl: 'https://apps.apple.com/ca/app/usewalter-manager/id1450910401?itsct=apps_box_badge&amp;itscg=30200',
  androidUrl: `https://play.google.com/store/apps/details?id=com.walter.manager&hl=${IS_FRENCH ? 'fr' : 'en'}_CA&gl=CA`,
}

export function RootProvider() {
  return (
    <I18nextProvider i18n={i18n}>
      <ApolloProvider client={apolloClient}>
        <ToastProvider>
          <PopoverProvider>
            <MessageProvider>
              <AuthProvider>
                <IntercomProvider>
                  <HotJarProvider>
                    <SkeletonTheme baseColor={Colors.white} highlightColor={Colors.offWhite}>
                      <Outlet />
                      <MobileBanner {...MOBILE_URLS} />
                    </SkeletonTheme>
                  </HotJarProvider>
                </IntercomProvider>
              </AuthProvider>
            </MessageProvider>
          </PopoverProvider>
        </ToastProvider>
      </ApolloProvider>
    </I18nextProvider>
  )
}
