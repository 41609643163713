import { z } from 'zod'
import { i18n, t } from '../../utils'

export const BoardMembersi18nFields = {
  user: t('tenant'),
}

export const boardMemberSchema = z.object({
  user: z.object(
    {
      id: z.string({ required_error: i18n.t('input-is-required', { field: BoardMembersi18nFields.user }) }),
      phone: z.object({ number: z.string() }).nullable().optional(),
    },
    { required_error: i18n.t('input-is-required', { field: BoardMembersi18nFields.user }) },
  ),
})

export const boardMemberUpdate = boardMemberSchema.extend({ id: z.string() })

export type BoardMember = z.infer<typeof boardMemberSchema>
